<template>
	<van-space direction="vertical" fill style="margin-top: 3rem">
		<van-form @submit="this.onSubmit">
			<van-cell-group inset>
				<van-field v-model="this.form.email"
						   type="username"
						   name="username"
						   :label="this.translate('Username', this.lang)"
						   placeholder="yourname"
						   :rules="[{ validator: (val) => this.inputValidate(val, 'username') }]"
				/>
				<van-field v-model="this.form.email"
						   type="email"
						   name="email"
						   :label="this.translate('E-mail', this.lang)"
						   placeholder="yourname@example.com"
						   :rules="[{ validator: (val) => this.inputValidate(val, 'email') }]"
				/>
				<van-field v-model="this.form.phone"
						   type="phone"
						   name="phone"
						   :label="this.translate('Phone', this.lang)"
						   placeholder="+1 123 456-78-90"
						   :rules="[{ validator: (val) => this.inputValidate(val, 'phone') }]"
				/>
				<van-field v-model="this.form.sms"
						   center
						   clearable
						   label="Verification"
						   placeholder="XX-XX-XX">
					<template #button>
						<van-button size="small" type="primary">Send SMS</van-button>
					</template>
				</van-field>
				<van-field v-model="this.form.password"
						   type="password"
						   name="password"
						   :label="this.translate('Password', this.lang)"
						   placeholder="Password"
						   :rules="[{ validator: (val) => this.inputValidate(val, 'password') }]"
				/>
				<van-field v-model="this.form.password_confirm"
						   type="password"
						   name="password_confirm"
						   :label="this.translate('... confirm', this.lang)"
						   placeholder="Password confirm"
						   :rules="[{ validator: (val) => this.inputValidate(val, 'password_confirm') }]"
				/>
			</van-cell-group>
			<van-cell-group inset>
				<van-field v-model="this.country_list.filter((option) => { option.value == this.form.city}).text"
						   is-link
						   readonly
						   label="Country"
						   placeholder="Choose Country"
						   @click="this.showCountryPicker = true"
				/>
				<van-field v-model="this.city_list.filter((option) => { option.value == this.form.city}).text"
						   is-link
						   readonly
						   label="City"
						   placeholder="Choose City"
						   @click="this.showCityPicker = true"
				/>

				<van-popup v-model:show="this.showCountryPicker"
						   round
						   position="bottom">
					<van-picker
						title="Country"
						:columns="this.country_list"
						@cancel="this.showCityPicker = false"
						@confirm="(selected) => {
							this.showCountryPicker = false;
							this.form.country = selected.value;
						}"
					/>
				</van-popup>
				<van-popup v-model:show="this.showCityPicker"
						   round
						   position="bottom">
					<van-picker
						title="City"
						:columns="this.city_list"
						@cancel="this.showCityPicker = false"
						@confirm="(selected) => {
							this.showCityPicker = false;
							this.form.city = selected.value;
						}"
					/>
				</van-popup>
			</van-cell-group>

			<van-doc-card>
				Test
			</van-doc-card>

			<div style="margin: 16px;">
				<van-button round
							block
							size="large"
							type="primary"
							color="linear-gradient(to right, #ff6034, #ee0a24)"
							native-type="submit">
					{{ this.translate('Sign Up', this.lang) }}
				</van-button>
				<div style="margin-top: 16px;font-size: 0.8rem;text-align: center;">
					{{ this.translate('By clicking "Sign Up" you accept our', this.lang) }}
					<a href="javascript:{}">{{ this.translate('Terms of Use', this.lang) }}</a>.
					{{ this.translate('To find out how we process your personal data, please see our', this.lang) }}
					<a href="javascript:{}">{{ this.translate('Privacy Policy', this.lang) }}</a>
					{{ this.translate('and', this.lang) }}
					<a href="javascript:{}">{{ this.translate('Cookie Policy', this.lang) }}</a>.
				</div>
			</div>
		</van-form>
	</van-space>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useCurrentLang } from 'vant';
import CommonService from '@/services/CommonService.js';

export default defineComponent({
	name: "sign-up",
	data() {
		return {
			title: "Sign Up",
			lang: 'en-US',
			messages: {
				'ru-RU': {

				},
				'uk-UA': {

				},
			},
			city_list: [
				{ text: 'Delaware', value: 'Delaware' },
				{ text: 'Florida', value: 'Florida' },
				{ text: 'Wenzhou', value: 'Wenzhou' },
				{ text: 'Indiana', value: 'Indiana' },
				{ text: 'Maine', value: 'Maine' },
			],
			country_list: [
				{ text: 'Delaware', value: 'Delaware' },
				{ text: 'Florida', value: 'Florida' },
				{ text: 'Wenzhou', value: 'Wenzhou' },
				{ text: 'Indiana', value: 'Indiana' },
				{ text: 'Maine', value: 'Maine' },
			],
			showCityPicker: false,
			showCountryPicker: false,
			form: {
				email: null,
				password: null,
				_errors: [],
			}
		};
	},
	components: {

	},
	mounted() {
		this.setPageTitle(this.title);
		this.lang = useCurrentLang();
	},
	methods: {
		setPageTitle(title) {
			CommonService.setPageTitle(title);
			this.emitter.emit("setPageTitle", title);
		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		toRoute(path, refresh) {

			if (refresh)
				window.location.href = path
			else
				this.$router.push(path);

		},
		translate(string, lang) {

			if (typeof lang !== "undefined") {
				if (!this.isEmpty(this.translations[lang])) {
					if (!this.isEmpty(this.translations[lang][string])) {
						return this.translations[lang][string];
					}
				}
			}

			return string;
		},
		inputValidate(value, input) {

			let message = null;

			if (input == 'email') {
				if (this.isEmpty(value)) {
					message = this.translate('E-mail is required', this.lang);
				} else if (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value))) {
					message = 'E-mail syntax is not valid';
				}
			}

			if (input == 'password') {
				if (this.isEmpty(value)) {
					message = this.translate('Password is required', this.lang);
				} else if (!(/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}/.test(value))) {
					message = 'Password strength is not valid';
				}
			}

			return message;
		},
		onSubmit() {
			console.info('onSubmit()');
		}
	}
});
</script>